import styled, { css } from 'styled-components';

interface InputBaseProps {
  error?: boolean;
}

export const styleInputBase = css<InputBaseProps>`
  outline-style: none;
  border-style: none;
  background-color: transparent;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  color: inherit;
  cursor: inherit;
  font-size: 18px;
  line-height: 20px;

  &::placeholder {
    color: #989898;
  }

  ${(props) =>
    props.error &&
    css`
      &::placeholder {
        color: #ef0c0c;
      }
    `}
`;

export const InputBase = styled.input`
  ${styleInputBase}
`;
