import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { GlobalStyle } from '@config/base/styles/global';

import ReactQueryProvider from '@libs/react-query';
import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => {
  return (
    <ReactQueryProvider>
      <div className="App">
        <Outlet />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ReactQueryDevtools />
        <GlobalStyle />
      </div>
    </ReactQueryProvider>
  );
};

export default App;
