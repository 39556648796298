import { Loading } from './styles';
import { PlaceholderProps } from './types';

export const Placeholder = ({
  width = `100%`,
  height = `1rem`,
  variant = `text`,
}: PlaceholderProps) => {
  return (
    <Loading
      variant={variant}
      style={{
        width: width,
        height: height,
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#E7E7E7',
      }}
    />
  );
};
