import styled, { css } from 'styled-components';

interface iTypographyProps {
  variant?:
    | 'overline'
    | 'caption'
    | 'small'
    | 'medium'
    | 'large'
    | 'h6'
    | 'h5'
    | 'h4'
    | 'h3'
    | 'h2'
    | 'h1';
  weight?: 'regular' | 'semibold' | 'bold';
  align?: 'left' | 'center' | 'right';
}

export const Typography = styled.p<iTypographyProps>`
  color: #686868;

  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          text-align: left;
        `;

      case 'center':
        return css`
          text-align: center;
        `;

      case 'right':
        return css`
          text-align: right;
        `;

      default:
        return css`
          text-align: left;
        `;
    }
  }}

  ${({ weight }) => {
    switch (weight) {
      case 'regular':
        return css`
          font-weight: 400;
        `;

      case 'semibold':
        return css`
          font-weight: 600;
        `;

      case 'bold':
        return css`
          font-weight: 700;
        `;

      default:
        return css`
          font-weight: 400;
        `;
    }
  }}

  ${({ variant }) => {
    switch (variant) {
      case 'overline':
        return css`
          font-size: 14px;
          line-height: 14px;
        `;

      case 'caption':
        return css`
          font-size: 16px;
          line-height: 16px;
        `;

      case 'small':
        return css`
          font-size: 18px;
          line-height: 18px;
        `;

      case 'medium':
        return css`
          font-size: 20px;
          line-height: 20px;
        `;

      case 'large':
        return css`
          font-size: 22px;
          line-height: 22px;
        `;

      case 'h6':
        return css`
          font-size: 24px;
          line-height: 24px;
        `;

      case 'h5':
        return css`
          font-size: 28px;
          line-height: 28px;
        `;

      case 'h4':
        return css`
          font-size: 32px;
          line-height: 32px;
        `;

      case 'h3':
        return css`
          font-size: 36px;
          line-height: 36px;
        `;

      case 'h2':
        return css`
          font-size: 40px;
          line-height: 40px;
        `;

      case 'h1':
        return css`
          font-size: 52px;
          line-height: 52px;
        `;

      default:
        return css`
          font-size: 16px;
          line-height: 16px;
        `;
    }
  }}
`;
