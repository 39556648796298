import styled, { css, keyframes } from 'styled-components';

const animation = keyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '50%': {
    transform: 'translateX(100%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

interface iLoadingProps {
  variant?: 'circle' | 'text';
}

export const Loading = styled.span<iLoadingProps>`
  &:after {
    animation: ${animation} 1.6s linear 0.5s infinite;
    content: '';
    position: absolute;
    transform: translateX(-100%);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-image: linear-gradient(90deg, transparent, #d2d2d2, transparent);
  }

  ${({ variant }) =>
    variant === 'text' &&
    css`
      margin-left: 0;
      margin-right: 0;
      border-radius: 8;
    `}

  ${({ variant }) =>
    variant === 'circle' &&
    css`
      border-radius: '999px';
    `}
`;
