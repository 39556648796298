import { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import Card from '@components/Card';
import { Placeholder } from '@components/Feedback/Placeholder';
import { SelectField } from '@components/Input/SelectField';
import { Typography } from '@components/Text/Typography';

import { useDashboardClass, useDashboardUser } from '@services/dashboard';

import formatNumber from '@utils/mask/formatNumber';

import { RechartsUserDashboard } from './types';

const Dashboard = () => {
  const [formattedData, setFormattedData] = useState<RechartsUserDashboard[]>(
    [] as RechartsUserDashboard[]
  );
  const [filter, setFilter] = useState<string>('All');

  const { search } = useLocation();

  const params = useMemo(
    () => ({
      type: new URLSearchParams(search).get('type') ?? '',
      u: new URLSearchParams(search).get('u') ?? '',
      t: new URLSearchParams(search).get('t') ?? '',
    }),
    [search]
  );

  const isClass = useMemo(() => params?.type === 'class', [params?.type]);

  const { data, isLoading } = useDashboardUser(params.u, params.t, {
    enabled: params?.type === 'user',
  });

  const { data: dataClass, isLoading: isLoadingClass } = useDashboardClass(
    params.u,
    params.t,
    { enabled: params?.type === 'class' }
  );

  const displayData = useMemo(() => {
    if (!isClass)
      return {
        errorAverage: data?.errorAverage
          ? formatNumber(data?.errorAverage, 'pt-BR', undefined, {
              maximumFractionDigits: 1,
            })
          : '-',
        timeAverage: data?.timeAverage
          ? formatNumber(data?.timeAverage, 'pt-BR', undefined, {
              maximumFractionDigits: 1,
            })
          : '-',
      };

    return {
      errorAverage: dataClass?.errorAverage
        ? formatNumber(dataClass?.errorAverage, 'pt-BR', undefined, {
            maximumFractionDigits: 1,
          })
        : '-',
      timeAverage: dataClass?.timeAverage
        ? formatNumber(dataClass?.timeAverage, 'pt-BR', undefined, {
            maximumFractionDigits: 1,
          })
        : '-',
    };
  }, [data, dataClass, isClass]);

  useEffect(() => {
    if (!data) return;

    const temp: RechartsUserDashboard[] = [];

    if (filter !== 'All') {
      const lesson = data.lessons.find(
        (lesson) => lesson.id === Number(filter)
      );

      lesson?.details.forEach((detail, index) => {
        temp.push({
          name: `Questão ${index + 1}`,
          tempo: detail.tempo,
          erros: detail.erros,
        });
      });

      setFormattedData(temp);

      return;
    }

    data?.lessons.forEach((lesson) => {
      temp.push({
        name: `Aula ${lesson.id}`,
        tempo: lesson.timeAverage,
        erros: lesson.errorAverage,
      });
    });

    setFormattedData(temp);
  }, [data, filter]);

  useEffect(() => {
    if (!dataClass) return;

    const temp: RechartsUserDashboard[] = [];

    if (filter !== 'All') {
      const lesson = dataClass.lessons.find(
        (lesson) => lesson.id === Number(filter)
      );

      lesson?.details.forEach((detail, index) => {
        temp.push({
          name: `Aula ${index + 1}`,
          tempo: detail.tempo,
          erros: detail.erros,
        });
      });

      setFormattedData(temp);

      return;
    }

    dataClass?.lessons.forEach((lesson) => {
      temp.push({
        name: `Aula ${lesson.id}`,
        tempo: lesson.timeAverage,
        erros: lesson.errorAverage,
      });
    });

    setFormattedData(temp);
  }, [dataClass, filter]);

  return (params?.type === 'user' && isLoading) ||
    (params?.type === 'class' && isLoadingClass) ? (
    <Placeholder width="100%" height="100%" />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: '64px',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '60%',
          height: '20%',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
        }}
      >
        <Card title="Media de tempo">
          <Typography variant="h2" weight="regular">
            {`${displayData.timeAverage} minutos`}
          </Typography>
        </Card>
        <Card title="Media de erros">
          <Typography variant="h2" weight="regular">
            {displayData.errorAverage}
          </Typography>
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          width: '60%',
          height: '40%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={formattedData}>
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(item) =>
                `${formatNumber(item, 'pt-BR', undefined, {
                  maximumFractionDigits: 1,
                })}`
              }
            />
            <Tooltip
              labelFormatter={(item) => `Médias da ${item}`}
              formatter={(item, name) =>
                `${
                  name === 'tempo'
                    ? `${formatNumber(Number(item), 'pt-BR', undefined, {
                        maximumFractionDigits: 1,
                      })} minutos`
                    : `${formatNumber(Number(item), 'pt-BR', undefined, {
                        maximumFractionDigits: 1,
                      })} erros`
                }`
              }
            />
            <Bar
              dataKey="tempo"
              barSize={30}
              fill="#82ca9d66"
              stroke="#82ca9d"
              radius={[10, 10, 0, 0]}
            />
            <Bar
              dataKey="erros"
              barSize={30}
              fill="#FF949466"
              stroke="#FF9494"
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            width: '20%',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
          }}
        >
          <SelectField
            name="user"
            label="Selecione a aula"
            placeholder="Selecione a aula"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="All">Todas</option>
            {isClass
              ? dataClass?.lessons.map((lesson) => (
                  <option key={lesson.id} value={lesson.id}>
                    Aula {lesson.id}
                  </option>
                ))
              : data?.lessons.map((lesson) => (
                  <option key={lesson.id} value={lesson.id}>
                    Aula {lesson.id}
                  </option>
                ))}
          </SelectField>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
