import {
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosInstance,
} from 'axios';

import { CookieKey, getCookie } from '@utils/cookies';

const onResponse = (response: AxiosResponse) => response;
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onRequest = async (config: AxiosRequestConfig) => {
  const token = getCookie(CookieKey.AuthToken, document.cookie);
  const { headers } = config;

  if (headers && (headers.Authorization || token)) {
    headers.Authorization = `${headers.Authorization || token}`;
  }

  return { ...config, headers };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
};
