import type { QueryKey } from '@tanstack/react-query';

export const createDashboardUser = (userId: string): QueryKey => [
  `useDashboardUser`,
  userId,
];

export const createDashboardClasses = (classId: string): QueryKey => [
  `useDashboardClasses`,
  classId,
];
