import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

const history = createBrowserHistory({ window });

interface Props {
  children: React.ReactNode;
}

const HistoryProvider: React.FC<Props> = ({ children }) => {
  return <HistoryRouter history={history}>{children}</HistoryRouter>;
};

export const navigate = (to: string): void => {
  history.push(to);
};

export default HistoryProvider;
