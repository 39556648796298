import styled, { css } from 'styled-components';

interface InputBaseContainerProps {
  focused?: boolean;
  error?: boolean;
  disabled?: boolean;
  cursor?: 'text' | 'pointer';
}

export const InputBaseContainer = styled.div<InputBaseContainerProps>`
  transition: fill 320ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid;
    border-color: #e7e7e7;
    pointer-events: none;
  }

  &::after {
    content: '';
    transition: transform 320ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      border-color 320ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid;
    pointer-events: none;
  }

  ${(props) =>
    props.focused &&
    css`
      &::after {
        transform: scaleX(1);
      }
    `}

  ${(props) =>
    props.error &&
    css`
      color: #ef0c0c;
      fill: #ef0c0c;

      &::after {
        border-color: #ef0c0c;
        transform: scaleX(1);
      }
    `}

  ${(props) =>
    !props.error &&
    css`
      &::after {
        border-color: #4507a1;
        transform: scaleX(0);
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      fill: currentColor;
      color: $neutral400;
    `}

  ${(props) =>
    props.cursor === 'text' &&
    css`
      cursor: text;
    `}

  ${(props) =>
    props.cursor === 'pointer' &&
    css`
      cursor: pointer;
    `}
`;
