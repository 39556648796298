import { Route, Routes } from 'react-router-dom';

import Home from '@home/screens/Home';

import App from '@src/App';

import { ROUTES } from './names';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.PUBLIC.ROOT()} element={<App />}>
        <Route path={ROUTES.PRIVATE.ROOT()} element={<Home />} />

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default Router;
