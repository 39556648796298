import { Route } from './types';

const mapParameters = (base: string): Route => {
  return (...args: string[]) => {
    return `/${base}${args.map((parameter) => `/${parameter}`).join(``)}`;
  };
};

export const ROUTES = {
  PUBLIC: {
    ROOT: mapParameters(``),
  },
  PRIVATE: {
    ROOT: mapParameters(`dashboard`),
  },
};
