/**
 * Get a locale number
 */
const formatNumber = (
  value: number,
  locale = 'pt-BR',
  style = 'decimal',
  options?: Intl.NumberFormatOptions
): string => {
  options = { style, ...options };
  return new Intl.NumberFormat(locale, options).format(value);
};

export default formatNumber;
