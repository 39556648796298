import styled, { css } from 'styled-components';

import { Typography } from '@components/Text/Typography';

interface InputLabelProps {
  focused?: boolean;
  disabled?: boolean;
  hasStartAdornment?: boolean;
}

export const InputLabel = styled(Typography)<InputLabelProps>`
  position: absolute;
  transform-origin: left top;
  left: 0;
  top: 0;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #989898;

  ${({ hasStartAdornment }) =>
    hasStartAdornment &&
    css`
      transform: translate(32px, 25px) scale(1);
    `}

  ${({ hasStartAdornment, focused }) =>
    !hasStartAdornment &&
    focused &&
    css`
      transform: translate(0px, 0px) scale(0.77);
      font-weight: 600;
    `}

${({ hasStartAdornment, focused }) =>
    !hasStartAdornment &&
    !focused &&
    css`
      transform: translate(0px, 25px) scale(1);
    `}

${({ disabled }) =>
    disabled &&
    css`
      color: #d2d2d2;
    `}
`;

InputLabel.defaultProps = {
  variant: 'large',
  hasStartAdornment: false,
  focused: false,
};
