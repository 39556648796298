import styled, { css } from 'styled-components';

import { Typography } from '@components/Text/Typography';

interface InputHelperTextProps {
  error?: boolean;
  disabled?: boolean;
}

export const InputHelperText = styled(Typography)<InputHelperTextProps>`
  margin-top: 2px;
  color: #989898;

  ${({ error }) =>
    error &&
    css`
      color: #ef0c0c;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: #d2d2d2;
    `}
`;

InputHelperText.defaultProps = {
  variant: 'caption',
  error: false,
};
