import { CardProps } from '@components/Card/types';
import { Typography } from '@components/Text/Typography';

const Card = ({ children, title, adornment }: CardProps) => {
  return (
    <div
      style={{
        backgroundColor: `transparent`,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#D2D2D2',
        boxShadow: `4px`,
        padding: `32px`,
        borderRadius: `20px`,
      }}
    >
      {(title || adornment) && (
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
            marginBottom: `24px`,
          }}
        >
          {title && (
            <Typography
              variant="h5"
              style={{ color: '#4507a1', fontWeight: `bold` }}
            >
              {title}
            </Typography>
          )}

          <div style={{ marginLeft: `16px` }}>{adornment && adornment}</div>
        </div>
      )}

      {children}
    </div>
  );
};

export default Card;
