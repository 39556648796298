import axios from 'axios';

import { BASE_URL } from '@config/environment';

import { setupInterceptors } from './interceptors';

export const baseApi = axios.create({
  baseURL: `${BASE_URL}/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': `application/json`,
    app_version: '1.0.0',
  },
});

setupInterceptors(baseApi);
