import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { baseApi } from '@libs/axios';

import parseData from '@utils/parseData';

import type { ApiError } from '@entities/Response';

import { createDashboardClasses, createDashboardUser } from './keys';
import type { DashboardClassResponse, DashboardUserResponse } from './types';

const tenSeconds = 10 * 1000;

export const useDashboardUser = (
  userId: string,
  token: string,
  options?: UseQueryOptions<DashboardUserResponse, ApiError>
) => {
  return useQuery(
    createDashboardUser(userId),
    () =>
      baseApi
        .get<DashboardUserResponse>(`/dashboard/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(parseData),

    {
      staleTime: Infinity,
      cacheTime: 0,
      keepPreviousData: true,
      retry: 2,
      retryDelay: tenSeconds,
      notifyOnChangeProps: [`data`, `error`],
      ...options,
    }
  );
};

export const useDashboardClass = (
  classId: string,
  token: string,
  options?: UseQueryOptions<DashboardClassResponse, ApiError>
) => {
  return useQuery(
    createDashboardClasses(classId),
    () =>
      baseApi
        .post<DashboardClassResponse>(
          `/dashboard/classes`,
          {
            class: classId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(parseData),

    {
      staleTime: Infinity,
      cacheTime: 0,
      keepPreviousData: true,
      retry: 2,
      retryDelay: tenSeconds,
      notifyOnChangeProps: [`data`, `error`],
      ...options,
    }
  );
};
