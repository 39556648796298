import Dashboard from '@home/components/Dashboard';

const HomeScreen = () => {
  return (
    <div
      style={{
        width: `100vw`,
        height: `100vh`,
        display: `flex`,
        alignItems: `stretch`,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: `column`,
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            padding: `32px`,
            overflowY: `auto`,
          }}
        >
          <Dashboard />
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
