import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import Icon from '@components/Icon';
import { InputBaseContainer } from '@components/Input/InputBaseContainer';
import { InputHelperText } from '@components/Input/InputHelperText';
import { InputLabel } from '@components/Input/InputLabel';
import { Container } from '@components/Input/TextField/styles';

import { Select } from './styles';
import { SelectFieldProps } from './types';

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (props, ref) => {
    const {
      testId,
      id,
      name,
      value,
      placeholder,
      disabled,
      label,
      onChange,
      error,
      children,
      helperText,
      shrink,
    } = props;

    const [focused, setFocused] = useState<boolean>(false);

    const hasContent = useRef<boolean>(false);

    const shouldMoveLabel = useMemo(() => {
      return (
        focused ||
        !!placeholder ||
        Boolean(value) ||
        hasContent.current ||
        Boolean(shrink)
      );
    }, [focused, placeholder, shrink, value]);

    const toggleFocus = useCallback(() => {
      setFocused((curr) => !curr);
    }, []);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        hasContent.current = !!e.target.value.length;
        onChange?.(e);
      },
      [onChange]
    );

    const renderPlaceholder = useMemo(() => {
      return placeholder && !focused && <option value="">{placeholder}</option>;
    }, [placeholder, focused]);

    return (
      <Container>
        {label && (
          <InputLabel
            as="label"
            htmlFor={id}
            focused={shouldMoveLabel}
            disabled={disabled}
          >
            {label}
          </InputLabel>
        )}

        <InputBaseContainer
          focused={focused}
          error={error}
          disabled={disabled}
          cursor="pointer"
        >
          <Select
            ref={ref}
            id={id}
            data-testid={testId}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            onFocus={toggleFocus}
            onBlur={toggleFocus}
            error={error}
            style={{
              minHeight: 40,
              color: shouldMoveLabel ? 'inherit' : 'transparent',
              appearance: 'none',
            }}
          >
            {renderPlaceholder}
            {children}
          </Select>

          <div
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: focused
                ? 'translateY(-50%) rotate(180deg)'
                : 'translateY(-50%) rotate(0deg)',
              transition: 'transform 320ms cubic-bezier(0, 0, 0.2, 1) 0ms',
              pointerEvents: 'none',
            }}
          >
            {disabled ? (
              <Icon name="IoChevronUpOutline" color="#D2D2D2" fontSize="24px" />
            ) : (
              <Icon
                name="IoChevronDownOutline"
                color={error ? '#EF0C0C' : '#989898'}
                fontSize="24px"
              />
            )}
          </div>
        </InputBaseContainer>

        {helperText && (
          <InputHelperText error={error} disabled={disabled}>
            {helperText}
          </InputHelperText>
        )}
      </Container>
    );
  }
);

SelectField.displayName = 'SelectField';
