import { useMemo } from 'react';

import * as Icons from 'react-icons/io5';

import { IconProps } from './types';

const Icon = ({
  color = `#686868`,
  fontSize = '32px',
  name,
  size,
}: IconProps) => {
  const renderIcon = useMemo(() => {
    const Icon = Icons[name];
    return <Icon color="inherit" fontSize="inherit" />;
  }, [name]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color,
        fill: color,
        fontSize: size ?? fontSize,
      }}
    >
      {renderIcon}
    </div>
  );
};

export default Icon;
